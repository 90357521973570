import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import DropdownMenu from '@innologica/vue-dropdown-menu'

Vue.use(Vuetify);
Vue.use(DropdownMenu);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    data: {
        show: false,
        right: false,
        hover: false,
        interactive: false
    }

});
