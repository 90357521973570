<template>
  <v-col align="center">
    <v-container>
      <v-card width="90vw">
        <v-tabs v-model="model">
          <v-tab href="#TOPIC">TOPIC</v-tab>
          <v-tab href="#PROFILE">PROFILE</v-tab>
          <v-tab href="#PROJECT">PROJECT</v-tab>
          <v-tab href="#RESEARCH">RESEARCH</v-tab>
          <v-tab href="#EVENT">EVENT</v-tab>
        </v-tabs>
        <v-tabs-items v-model="model">
          <v-tab-item value="TOPIC">
            <edit-topic @edit="edit" />
          </v-tab-item>
          <v-tab-item value="PROFILE">
            <edit-profile @edit="edit" />
          </v-tab-item>
          <v-tab-item value="PROJECT">
            <edit-project @edit="edit" />
          </v-tab-item>
          <v-tab-item value="RESEARCH">
            <edit-research @edit="edit" />
          </v-tab-item>
          <v-tab-item value="EVENT">
            <edit-event @edit="edit" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-dialog v-model="dialog_topic">
        <Dialog_Topic :item="topic" :mode="mode" :flg="dialog_topic" @dialog_bloken="dialog_topic = false" />
      </v-dialog>
      <v-dialog v-model="dialog_project">
        <Dialog_Project :item="project" :mode="mode" @dialog_bloken="broken_dialog" />
      </v-dialog>
      <v-dialog v-model="dialog_profile">
        <Dialog_Profile :item="profile" :mode="mode" :flg="dialog_profile" @dialog_bloken="broken_dialog" />
      </v-dialog>
    </v-container>
  </v-col>
</template>

<script>
import Edit_Topic from '@/components/form/topic';
import Edit_Profile from '@/components/form/profile';
import Edit_Project from '@/components/form/project';
import Edit_Research from '@/components/form/research';
import Edit_Event from '@/components/form/event';
import Dialog_Topic from '@/components/dialog/topic';
import Dialog_Project from '@/components/dialog/project';
import Dialog_Profile from '@/components/dialog/profile';
export default {
  components: {
    'edit-topic': Edit_Topic,
    'edit-profile': Edit_Profile,
    'edit-project': Edit_Project,
    'edit-research': Edit_Research,
    'edit-event': Edit_Event,
    Dialog_Topic,
    Dialog_Project,
    Dialog_Profile,
  },
  async created() {},
  data() {
    return {
      dialog_topic: false,
      dialog_project: false,
      dialog_profile: false,
      model: 'TOPIC',
      topic: {},
      project: {},
      mode: '',
      item: {},
      category: '',
      profile: {},
    };
  },
  methods: {
    edit(item, mode, category) {
      this.mode = mode;
      this.item = item;
      if (category == 'topic') (this.topic = item), (this.dialog_topic = true);
      if (category == 'project') (this.project = item), (this.dialog_project = true);
      if (category == 'profile') (this.profile = item), (this.dialog_profile = true);
    },
    broken_dialog() {
      this.item = {};
      this.profile = this.project = this.topic = {};
      this.dialog_project = false;
      this.dialog_topic = false;
      this.dialog_profile = false;
    },
  },
};
/*post(val, mode, kinds) {
      console.log(mode)
      if (kinds == "topic") {
        if (confirm("トピックの投稿")) {
          console.log(this.topic);
          this.topic = { text: "", img: null };
        }
      } else if (kinds == "profile") {
        if (confirm("プロフィールの投稿")) {
          console.log(this.profile);
          this.profile = {
            name: "",
            comment: "",
            img: null,
          };
        }
      } else if (kinds == "project") {
        console.log("val", val);
      }else if (kinds == "research") {
        if (confirm("研究内容の投稿")) {
          console.log(this.research);
          this.research = {
            select2: "",
            comment: "",
            year: "",
            };
        }
      }
      else if (kinds == "event") {
        if (confirm("研究内容の投稿")) {
          console.log(this.event);
          this.event = {
            };
        }
      }
    },*/
/*
    let date = new Date(
      Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
    );
    let now =
      date.getFullYear() +
      "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2);
    console.log(now);*/
</script>
