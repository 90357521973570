<template>
  <div class="block">
    <v-container>
      <h1>PROJECT</h1>
    </v-container>

    <v-container v-for="src in list" :key="src.title">
      <div class="block2" align="left">
        <h2>
          <router-link :to="{ name: 'project_view', params: { id: src.id } }">
            {{ src.title }}
          </router-link>
        </h2>
      </div>
    </v-container>
  </div>
</template>

<script>
import { getItem } from '@/plugins/firebase';

export default {
  data() {
    return {
      list: [],
    };
  },

  async created() {
    this.list = await getItem('project');
    //forで今までは回していたが，パラメータの受け渡しでできるようにしたい．
  },
};
</script>
