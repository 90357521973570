<template>
  <v-card>
    <v-col cols="20">
      <p class="text-left">タイトル</p>
      <div>
        <quill-editor v-model="item.title" label="title" ref="quillEditor"> </quill-editor>
      </div>
      <br />
      <p class="text-left">コメント</p>
      <div>
        <quill-editor v-model="item.comment" label="comment" ref="quillEditor"> </quill-editor>
      </div>
    </v-col>
    <v-col cols="20">
      <p class="text-left">投稿日時(日時指定する場合)</p>
      <div>
        <v-text-field v-model="item.time" label="YYYY.MM.DD" :rules="required" />
      </div>
    </v-col>
    <v-col>
      <input type="file" style="display: none" ref="input" accept="image/*" multiple @change="onUploaded" />
      <v-btn color="primary" @click="btnclick">select image</v-btn>
      <div v-if="image !== null || item.img !== null">
        <div v-for="img in image" :key="img.url">
          <img :src="img.url" width="50%" height="50%" />
          <br />
          <v-btn @click="delete_img(img)"> 画像を削除する </v-btn>
        </div>
      </div>
      <!-- <img v-if="item.img" :src="url" width="100" height="100" /> -->
    </v-col>
    <v-col align="right">
      <v-btn v-if="mode == 'update'" color="error" @click="deletion">削除</v-btn>
      <v-btn color="primary" @click="post">登録</v-btn>
    </v-col>
  </v-card>
</template>

<script>
import { postItem, uploadFile, deleteItem } from '@/plugins/firebase'; //import {deleteItem} from "@/plugins/firebase"
export default {
  props: ['item', 'mode', 'flg'],
  data() {
    return {
      image: [],
      file: [],
      time: '',
      required: [
        (value) => /\d{4}.\d{2}.\d{2}/.test(value) || 'YYYY.MM.DD', // 	\d{4} + "/" + \d{2} + "/" + \d{2}
      ],
    };
  },
  methods: {
    btnclick() {
      this.$refs.input.click();
    },
    async onUploaded(e) {
      // event(=e)からデータを取得する
      Array.from(e.target.files).forEach((elem) => {
        const file = {
          data: elem,
          url: URL.createObjectURL(elem),
        };
        this.image.push(file);
      });
    },
    delete_img(img) {
      if (confirm('本当に写真を削除しますか?')) {
        console.log(this.image);
        this.image.splice(
          this.image.findIndex((v) => v === img),
          1
        );
      }
    },
    async post() {
      let check = true;
      for (const v of this.image) {
        if (v.data != null) this.item.img.push(await uploadFile(v.data, 'image'));
      }
      if (this.mode == 'new' && this.item.time == '') this.item.time = this.time;
      //正規表現を使ってthis.item.time!=""でYYYY/MM/DDの形でなければ投稿できないようにする
      else if (this.mode == 'new' && this.item.time != '' && !/\d{4}.\d{2}.\d{2}/.test(this.item.time)) {
        window.alert('投稿日の形式が異なります');
        check = false;
      }
      if (check) {
        console.log(this.item);
        await postItem('topic', this.item, this.mode);
        await alert('投稿しました');
        this.$emit('dialog_bloken');
      }
    },
    async deletion() {
      if (confirm(this.item.title + 'を本当に削除しますか')) {
        await deleteItem('topic', this.item);
        await alert('削除しました');
        this.$emit('dialog_bloken');
      }
    },
  },
  created() {
    if (this.image != []) this.image = [];
    for (const v of this.item.img) {
      const file = {
        data: null,
        url: v,
      };
      this.image.push(file);
    }
    const jst = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
    const d = new Date();
    this.time = String(d.getFullYear(jst)) + '.' + String(d.getMonth(jst) + 1).padStart(2, '0') + '.' + String(d.getDate(jst)).padStart(2, '0');
  },
  destoryed() {
    this.item = {
      title: '',
      comment: '',
      img: [],
      time: '',
    };
    this.image = [];
  },
  watch: {
    flg: function () {
      console.log(this.item);
      if (this.flg) {
        if (this.image != []) this.image = [];
        for (const v of this.item.img) {
          const file = {
            data: null,
            url: v,
          };
          this.image.push(file);
        }
      }
    },
  },
};
</script>
