<template>
  <v-col cols="12">
    <v-btn color="error" @click="create"> 新規作成 </v-btn>
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    <v-data-table :headers="headers" :items="topic_list" :items-per-page="15" item-key="id" :search="search" multi-sort>
      <template v-slot:[`item.title`]="{ item }">
        <span v-html="item.title"></span>
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <span v-html="item.comment"></span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import { getItem } from '@/plugins/firebase';
export default {
  name: 'Topic',
  data() {
    return {
      headers: [
        { text: '投稿日', value: 'time' },
        { text: 'タイトル', value: 'title' },
        { text: 'コメント', value: 'comment' },
        { text: '編集', value: 'edit', sortable: false },
      ],
      search: '',
      topic: {
        title: '',
        comment: '',
        img: [],
        time: '',
      },
      topic_list: [
        {
          title: '',
          comment: '',
          img: [],
        },
      ],
    };
  },
  methods: {
    create() {
      const create_item = {
        title: '',
        comment: '',
        img: [],
        time: '',
      };
      this.$emit('edit', create_item, 'new', 'topic');
    },
    edit(item) {
      this.$emit('edit', item, 'update', 'topic');
    },
  },
  computed: {},
  async created() {
    this.topic_list = await getItem('topic');
    console.log('topic_list', this.topic_list);
  },
};
</script>
