<template>
  <v-app>
    <Top />
    <v-main>
      <router-view />
    </v-main>
    <Sidebar />
    <Bottom />
  </v-app>
</template>

<script>
import Top from "@/components/bar/Top";
import Bottom from "@/components/bar/Bottom";
import Sidebar from "@/components/bar/Sidebar";

export default {
  name: "App",

  components: {
    Top,
    Sidebar,
    Bottom,
  },

  data: () => ({
    //test
  }),
};
</script>
<!--
<style scoped>
.main {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.side_bar {
  position: absolute;
}
</style>
-->
