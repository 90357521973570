<template>
  <div>
    <v-col align="left">
      <v-container class="top">
        <h1>TOPICS</h1>
      </v-container>

      <div id="app">
        <v-main class="pa-0">
          <v-pagination v-model="page" :length="length" @input="pageChange"></v-pagination>
          <v-container class="main" v-for="topic in displayLists" :key="topic.title">
            <p v-html="topic.time"></p>
            <p v-html="topic.title"></p>
            <div v-if="topic.img !== null">
              <img style="max-width: 60%" v-for="img in topic.img" :key="img" :src="img" />
            </div>
            <p v-html="topic.comment"></p>
            <v-divider></v-divider>
          </v-container>
          <v-pagination v-model="page" :length="length" @input="pageChange"></v-pagination>
        </v-main>
      </div>
    </v-col>
  </div>
</template>

<script>
import { getItem } from '@/plugins/firebase';
export default {
  name: 'App',
  data() {
    return {
      page: 1,
      length: 0,
      topics: [],
      displayLists: [],
      pageSize: 10,
    };
  },
  methods: {
    pageChange: function (pageNumber) {
      this.displayLists = this.topics.slice(this.pageSize * (pageNumber - 1), this.pageSize * pageNumber);
    },
  },
  async created() {
    this.topics = await getItem('topic');
    this.length = Math.ceil(this.topics.length / this.pageSize);
    this.displayLists = this.topics.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);
  },
};
</script>
<style>
.main {
  max-width: 900px;
}
.top {
  max-width: 900px;
}
</style>
