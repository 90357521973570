<template>
  <v-col align="center">
    <v-container>
      <!-- 住所 -->
      <div class="block">
        <h2>住所</h2>
      </div>
      <div class="block2">日本大学理工学部船橋キャンパス2号館243<br /><br /></div>

      <!-- 連絡先 -->
      <div class="block">
        <h2>連絡先</h2>
      </div>
      <div class="block2">
        <p>ご質問などはメール（matsuno.yutaka at nihon-u.ac.jp (at を @に置き換えて下さい)）でご連絡ください。</p>
      </div>
    </v-container>
  </v-col>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  async created() {
    this.list = [
      {
        img: 'https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/homeimage.fc29f68f.png',
      },
    ];
  },
  methods: {},
  computed: {},
};
</script>
