<template>
  <v-col align="center">
    <v-container v-for="(src, index) in list" :key="index">
      <img
        style="max-width: 100%"
        class="alignleft size-thumbnail wp-image-583"
        alt=""
        :src="src.img"
      />
    </v-container>
    <div id="app"> 
      <v-main class="pa-0">
        <v-container>
            <v-col cols="8" style="min-width: 450px">
            <h2>研究キーワード
            </h2>
            <p>
              プログラミング言語,ソフトウエア工学,安全性,ディペンダビリティ,システム保証,セキュリティ,WEBセキュリティ,IoTセキュリティ,プログラム解析

            </p>
            <v-spacer />
            <h2>研究内容</h2>
            <v-sheet>
                  松野の経歴、論文などは松野の<a href=https://researchmap.jp/yutaka_matsuno>個人ページ</a>を御覧ください。<br/>
                  これまで松野はプログラミング言語や、システムの安全性、ディペンダビリティ、システム保証などを結構幅広く研究してきました。
                  ディペンダビリティは、英語ではDependability、日本語の意味は、「安全・安心」が近いかもしれません。
                  自動車や飛行機など、あるいは身の回りにはスマホなど、多くの「システム」があふれています。
                  それらシステムが我々にとってどうあれば、「安全・安心」になるかを大きな研究テーマにしています。
                  システム保証は、「このシステムは安全・安心です」とシステムに関係する人たち（利害関係者、ステークホルダ（Stakeholder）といいます）に納得してもらうことです。<br/>
                  これに関しては、松野が主催している<a href=http://www.dcase.jp/>D-Caseプロジェクト</a>のホームページを御覧ください。<br />
                  2015年に日大に来てからは、特に、多くの学生の能力や興味を生かした、大学研究室におけるアジャイルプロセスの提案と実践など、
                  実際にソフトウェアを研究室でどのように作ればよいのかを研究しています。そして、学生の皆さんが開発した
                  ソフトウェアをもとに、防災教育アプリに関する研究などを国際会議で研究発表しています。<br />
                  さらに最近では松野のもともとの研究分野であるプログラミング言語の研究を再開しました。<br />
                  依田は、これまでプログラム解析によるWEBアプリケーションのバグ検出、バイナリ解析によるIoTデバイスの脆弱性検出に取り組んできました。また、最近は、ソフトウエアセキュリティにおけるAIの活用についても研究しています。他にも、ソフトウエアの利用者や攻撃者の心理に関心のある学生も歓迎します。
                  今後、本研究室は、ソフトウエアの信頼性とセキュリティの確保を目指した研究をすすめていきます。
                  コンピュータサイエンス、ソフトウェア工学の基礎から応用まで、学生のみなさんと一緒に研究していきたいです。
            </v-sheet>
            <v-spacer />

            <h2 align="left">指導内容</h2>
            <p>コンピュータサイエンス、ソフトウェア工学の基礎を重視し、社会にでて役に立つことを指導していきたいと思います。学生の皆さんに研究してもらうことは、松野の研究テーマに沿ってもいいし、学生が自分でやりたいことがあれば、積極的にサポートしていきたいです。いまのところ以下の様なゼミを考えています。</p>
            <h3 align="left">３年生ゼミ</h3>
            <p>週２回程度のゼミ。Unity,アジャイル開発、ディープラーニングの基礎などを輪講、演習形式で学習します。新たに学習したい内容があれば相談します。後半では１チーム4人程度で、自分たちが作りたいソフトウエアを作ってもらいます。</p>
            <h3 align="left">４年生卒研</h3>
            <p>週2回程度のゼミ。毎週のミーティングとペアプログラミングなどによるソフトウェア開発を、アジャイルプロセスに沿って進めてもらいます（プログラミング言語など、個人で進める場合もあります）。優秀な研究成果が出た場合、学会などへ積極的に投稿してもらい、外部の様々な人と議論してもらいたいです。</p>
            <h3 align="left">修士課程ゼミ</h3>
            <p>自主性をより重視します。テーマは、松野のテーマでもいいし、みなさんがやりたいことのテーマでもいいです。今まで松野研究室でやっていなかった、自然言語処理をやりたくて、研究テーマにした学生もいます。松野は、それらを研究として形作るためのサポートを積極的にしていきたいです。ゼミは週１回程度やります。優秀な研究成果が出た場合、学会などへ積極的に投稿してもらい、外部の様々な人と議論してもらいたいです。</p>

            <v-divider></v-divider>
        </v-col>
        </v-container>
      </v-main>
    </div>
    
    </v-col
  >
</template>

<script>

export default {
  data() {
    return {
      list: [],
    };
  },
  async created() {
    this.list = [
      {
        img:
          "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/homeimage.fc29f68f.png",
      },
    ];
  },
  methods: {},
  computed: {},
};
</script>