<template>
  <v-col cols="12">
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    <v-data-table :headers="headers" :items="research_list" :items-per-page="15" item-key="id" :search="search" multi-sort>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small class="mr-2" @click="log(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
export default {
  name: 'Research',
  props: ['val'],
  data() {
    return {
      research_list: this.val,
      headers: [
        { text: '分類', value: 'select2' },
        { text: '研究内容', value: 'comment' },
        { text: '年度', value: 'year' },
        { text: '編集', value: 'edit', sortable: false },
      ],
      search: '',
    };
  },
  methods: {
    log(val) {
      console.log(val);
    },
  },
  computed: {},
};
</script>
