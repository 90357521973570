<template>
  <v-card>
    <v-col cols="20">
      <p class="text-left">名前</p>
      <div>
        <v-text-field v-model="item.name" label="name" />
      </div>
      <br />
      <p class="text-left">一言</p>
      <div>
        <v-text-field v-model="item.comment" label="comment" ref="quillEditor" />
      </div>
    </v-col>
    <v-col cols="20">
      <p class="text-left">学年</p>
      <div>
        <v-select v-model="item.grade" :rules="required"
        :items="['博士課程', '修士2年', '修士1年', '学部4年', '学部3年', '短大2年']">
        </v-select>
      </div>
    </v-col>
    <v-col>
      <input type="file" style="display: none" ref="input" accept="image/*" multiple @change="onUploaded" />
      <v-btn color="primary" @click="btnclick">select image</v-btn>
      <div v-if="image.url != '' || item.icon !== ''">
        <div>
          <img :src="image.url" width="50%" height="50%" />
          <br />
          <v-btn @click="delete_img()"> 画像を削除する </v-btn>
        </div>
      </div>
    </v-col>
    <v-col align="right">
      <v-btn v-if="mode == 'update'" color="error" @click="deletion">削除</v-btn>
      <v-btn v-if="mode == 'update'" color="primary" @click="update">更新</v-btn>
      <v-btn v-if="mode != 'update'" color="primary" @click="post">登録</v-btn>
    </v-col>
  </v-card>
</template>

<script>
import { postItem, uploadFile, deleteItem } from '@/plugins/firebase'; //import {deleteItem} from "@/plugins/firebase"
import { AccelerateConfigurationFilterSensitiveLog } from '@aws-sdk/client-s3';
export default {
  props: ['item', 'mode', 'flg'],
  data() {
    return {
      image: {
        url: '',
        data: '',
      },
      file: [],
      time: '',
      required: [

      ],
    };
  },
  methods: {
    btnclick() {
      this.$refs.input.click();
    },
    async onUploaded(e) {
      // event(=e)からデータを取得する
      if (this.image.date != '') {
        Array.from(e.target.files).forEach((elem) => {
          this.image = {
            data: elem,
            url: URL.createObjectURL(elem),
          };
        });
      }
    },
    delete_img() {
      if (confirm('本当に写真を削除しますか?')) {
        this.image = {
          url: 'https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/l_e_others_501.f2b6eafb.png',
          data: '',
        };
      }
    },
    async post() {
      let check = true;
      this.item.icon = 'https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/l_e_others_501.f2b6eafb.png';
      if (this.image.data != '' && this.image.url != '') this.item.icon = await uploadFile(this.image.data, 'image');
      if (check) {
        await postItem('profile', this.item, this.mode);
        await alert('投稿しました');
        this.image = {
          url: '',
          data: '',
        };
        this.item = {
          name: '',
          comment: '',
          icon: '',
        };
        this.$emit('dialog_bloken');
      }
    },
    async update() {
      //画像を変えるとき=オブジェクトファイルでURLはローカル 変えない時=でURLはグーグルストレージを表示
      if(this.image.data !== null && this.image.data != '') {
        this.item.icon = await uploadFile(this.image.data, 'image');
      }else{
        this.item.icon = this.image.url;
      }
      await postItem('profile', this.item, this.mode);
      await alert('投稿しました');
      this.image = {
        url: '',
        data: '',
      };
      this.item = {
        name: '',
        comment: '',
        icon: '',
      };
      this.$emit('dialog_bloken');
    },
    async deletion() {
      if (confirm(this.item.name + 'を本当に削除しますか')) {
        await deleteItem('profile', this.item);
        await alert('削除しました');
        this.$emit('dialog_bloken');
      }
    },
  },
  created() {
    if (this.image != []) this.image = [];
    this.image = {
      data: null,
      url: this.item.icon,
    };
  },
  destoryed() {
    this.item = {
      title: '',
      comment: '',
      img: [],
    };
    this.image = [];
  },
  watch: {
    flg: function () {
      console.log(this.item);
      const x = {
        url: '',
        data: '',
      };
      if (this.flg) {
        if (this.image != x) this.image = x;
        this.image = {
          data: null,
          url: this.item.icon,
        };
      }
    },
  },
};
</script>
