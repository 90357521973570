<template>
  <v-app>
    <v-main class="bg-light-blue-lighten-5 custom-main-padding">
      <v-container fluid>
        <v-row justify="center" no-gutters>
          <v-col cols="12" md="10" lg="8">
            <v-card class="mt-3 pa-4" elevation="5">
              <div class="d-flex flex-column align-center">
                <h1 class="text-h3 font-weight-bold text-center primary--text mb-4 custom-title">
                  第10回シリアスゲームジャム
                </h1>
                <h2 class="text-h5 text-center secondary--text mb-4">
                  The 10th Serious Game Jam
                </h2>
                <p class="text-h6 text-center accent--text mb-6">
                  〜ゲームで再定義する新しい防災訓練のカタチ〜
                </p>
              </div>

              <v-img
                :src="list[0].img"
                alt="SGJ10画像"
                class="mb-6"
                max-height="400"
                contain
              ></v-img>

              <v-row>
                <v-col cols="12" md="6">
                  <!-- 左側のカラム内容 -->
                  <v-card class="pa-4 mb-4" outlined>
                    <v-card-title class="text-h5 primary--text">
                      <v-icon large color="primary" class="mr-2">mdi-information-outline</v-icon>
                      開催概要
                    </v-card-title>
                    <!-- 開催概要の内容 -->
                    <v-card-text>
  <p>シリアスゲームジャムとは，社会における様々な課題をゲームのチカラで解決することを目的とし，専門家とゲーム開発者と学生らが力を結集して短期間でシリアスゲームを制作するイベントです。SGJ10では、三日間でシリアスゲームの開発手法を学び、体験していただけます。<br>また、SGJ10では見学のみの参加も受け付けています。</p>
  <v-list dense>
    <v-list-item>
      <v-list-item-icon class="mr-3">
        <v-icon color="secondary">mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="wrap-text">日程: 2024年8月27日(火) 〜 8月29日(木)</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon class="mr-3">
        <v-icon color="secondary">mdi-map-marker</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="wrap-text">会場: 日本大学 理工学部 駿河台キャンパス タワースコラ2階 S204</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon class="mr-3">
        <v-icon color="secondary">mdi-currency-usd-off</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>参加費/見学費: 無料</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card-text>
<v-card-actions>
  <v-btn
        ref="originalApplyBtn"
        color="primary"
        href="https://docs.google.com/forms/d/e/1FAIpQLSdJBoMWQe0cG7QK1Y1tx4JFLMHCLjmS5c8QiX_mdmujUr5laA/viewform?usp=sf_link"
        target="_blank"
        block
        large
        class="custom-apply-btn"
      >
        <v-icon left>mdi-pencil</v-icon>
        <span class="custom-btn-text">参加申し込み</span>
      </v-btn>
      
</v-card-actions>
<v-card-actions>
<v-btn
    color="secondary"
    href="https://docs.google.com/forms/d/e/1FAIpQLSeGVrKdpblHthxjMAaYO0722eftZMlSJXyzc_0Cp7s4_KuwAQ/viewform?usp=sf_link"
    target="_blank"
    block
    large
    class="mt-2"
  >
  <v-icon left>mdi-pencil</v-icon>
    見学申し込み
  </v-btn>
  </v-card-actions>
                  </v-card>

                  <v-card class="pa-4 mb-4" outlined>
                    <v-card-title class="text-h5 primary--text">
                      <v-icon large color="primary" class="mr-2">mdi-handshake</v-icon>
                      スポンサー・後援
                    </v-card-title>
                    <!-- スポンサー・後援の内容 -->
                    <v-card-text>
  <p>SGJ10スポンサ募集用サイトを整備していただきました。後援・協賛・スポンサを募っておりますので是非ご覧ください！</p>
  <v-btn
    color="secondary"
    href="https://www.mediadesignlabs.org"
    target="_blank"
    class="mt-2"
  >
    SGJスペシャルサイト
  </v-btn>
</v-card-text>
                  </v-card>
                  
                  
<v-card class="pa-4 mb-4" outlined>
  <v-card-title class="text-h5 primary--text d-flex align-center mb-3">
    <v-icon large color="primary" class="mr-2">mdi-information</v-icon>
    SGJ10 事前説明会
  </v-card-title>
  
  <v-card-text>
    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-calendar-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">日時</v-list-item-title>
          <v-list-item-subtitle>8月6日 18:00〜</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    
    <v-card outlined class="mt-4 mb-4">
      <v-card-title class="text-h6">
        <v-icon color="primary" class="mr-2">mdi-video</v-icon>
        Zoomミーティング情報
      </v-card-title>
      <v-card-text>
        <p>事前説明会は終了いたしました。
        <br>説明会の資料を掲載いたします。</p>
        <div class="video-container">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Ul4jRQjVnGc"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
      </v-card-text>
    </v-card>
    <v-card outlined class="mb-4">
      <v-card-title class="text-h6">
        <v-icon color="primary" class="mr-2">mdi-file-pdf-box</v-icon>
        説明会資料 (PDF)
      </v-card-title>
      <div class="pdf-viewer-container">
        <iframe src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/pdf/SGJ10-introduction.pdf"
        width="100%"
        style="height: 320px;"
        class="pdf-iframe"></iframe>
      </div>
    </v-card>
  </v-card-text>
</v-card>

                  <!-- SGJ10の流れ -->
<v-card class="pa-4 mb-4" outlined>
  <v-card-title class="text-h5 primary--text">
    <v-icon large color="primary" class="mr-2">mdi-clock-outline</v-icon>
    SGJ10の流れ
  </v-card-title>
  <v-card-text>
    <v-timeline dense>
      <v-timeline-item color="primary" small>
        <div class="d-flex justify-space-between align-center">
          <v-card class="elevation-2 flex-grow-1">
            <v-card-title class="text-h6">8月27日(火)<br>9:30-19:00</v-card-title>
            <v-card-text>
              ・シリアスゲーム作成方法とテーマ説明<br>
              ・チームビルディング（5人1組）<br>
              ・ゲームデザインと開発開始
            </v-card-text>
          </v-card>
        </div>
      </v-timeline-item>
      
      <v-timeline-item color="secondary" small>
        <div class="d-flex justify-space-between align-center">
          <v-card class="elevation-2 flex-grow-1">
            <v-card-title class="text-h6">8月28日(水)<br>9:00-19:00</v-card-title>
            <v-card-text>
              ・開発継続<br>
              ・中間発表会<br>
              ・実行委員からのミッション実施
            </v-card-text>
          </v-card>
        </div>
      </v-timeline-item>

      <v-timeline-item color="accent" small>
        <div class="d-flex justify-space-between align-center">
          <v-card class="elevation-2 flex-grow-1">
            <v-card-title class="text-h6">8月29日(木)<br>9:00-18:00</v-card-title>
            <v-card-text>
              ・開発最終日<br>
              ・プレゼンテーション準備<br>
              ・最終発表会
            </v-card-text>
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-card-text>
</v-card>
                </v-col>

                
                
                <v-col cols="12" md="6">
                  <!-- 右側のカラム内容 -->
                  <v-card class="pa-4 mb-4" outlined>
  <v-card-title class="text-h5 primary--text">
    <v-icon large color="primary" class="mr-2">mdi-gamepad-variant</v-icon>
    SGJ10のテーマ
  </v-card-title>
  <v-card-text>
    <v-row>
      <v-col cols="12">
        <p class="text-body-1">
          私たちの日常に潜む自然災害のリスク。いつ起こるかわからない緊急事態に備え、防災訓練は欠かせません。でも正直なところ、訓練はつまらなく、マンネリ化していませんか？
        </p>
      </v-col>
      <v-col cols="12">
        <v-alert
          color="info"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-lightbulb"
        >
          そこで、私たちは発想を転換します。ゲームの力で、防災訓練を楽しく、わくわくするアクティビティに変えてみるのです！
        </v-alert>
      </v-col>
      <v-col cols="12">
        <p class="text-body-1">
          避難所生活をリアルに体験できるシミュレーションゲーム、災害時の適切な行動を学べるクイズアドベンチャー、地域住民との協力プレイで絆を深められるパズルゲームなど、アイデアは無限大！ゲームならではの没入感と意外性で、防災への意識を高め、いざという時に役立つスキルを身につけられるはずです。
        </p>
      </v-col>
    </v-row>

  </v-card-text>

</v-card>
                  

                  <!-- その他の右側カラムの内容（シリアスゲーム・ゲームジャムとは？、特徴、流れ、過去のゲームジャム） -->
                  <!-- シリアスゲーム・ゲームジャムとは？ -->
<v-card class="pa-4 mb-4" outlined>
  <v-card-title class="text-h5 primary--text">
    <v-icon large color="primary" class="mr-2">mdi-information</v-icon>
    シリアスゲーム・ゲームジャムとは？
  </v-card-title>
  <v-card-text>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>シリアスゲーム</v-expansion-panel-header>
        <v-expansion-panel-content>
          世の中における具体的な課題解決を目的として開発されたゲームです。医療・福祉・教育等様々な分野での応用が期待されています。
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>ゲームジャム</v-expansion-panel-header>
        <v-expansion-panel-content>
          プロ・アマを問わずゲーム制作者が集まって短時間でゲームを制作するイベントです。国際的なイベントとして知られるGlobal Game Jam (GGJ) などがあります。
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card-text>
</v-card>



<!-- シリアスゲームジャムの特徴 -->
<v-card class="pa-4 mb-4" outlined>
  <v-card-title class="text-h5 primary--text">
    <v-icon large color="primary" class="mr-2">mdi-star</v-icon>
    シリアスゲームジャムの特徴
  </v-card-title>
  <v-card-text>
    <v-list dense>
      <v-list-item>
        <v-list-item-icon class="mr-3">
          <v-icon color="secondary">mdi-checkbox-marked-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="wrap-text">社会課題をテーマにシリアスゲームを作成</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon class="mr-3">
          <v-icon color="secondary">mdi-checkbox-marked-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="wrap-text">専門家による制作手順とテーマの教示</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon class="mr-3">
          <v-icon color="secondary">mdi-checkbox-marked-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="wrap-text">「リサーチャー」役職でゲーム開発以外も参加可能</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-alert
      color="info"
      text
      class="mt-3"
    >
      開発時間は朝9時〜19時までで、働き方に配慮しています。
    </v-alert>
  </v-card-text>
</v-card>


<!-- 過去のゲームジャム -->
<v-card class="pa-4 mb-4" outlined>
    <v-card-title class="text-h5 primary--text">
      <v-icon large color="primary" class="mr-2">mdi-history</v-icon>
      過去のゲームジャム
    </v-card-title>
    <v-card-text>
      <div class="custom-tabs-container" ref="tabsContainer">
        <v-tabs
          v-model="activeTab"
          background-color="transparent"
          show-arrows
          @change="handleTabChange"
        >
          <v-tab v-for="tab in tabs" :key="tab">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="tab in tabs" :key="tab">
          <v-card flat>
            <v-card-text>
              <v-btn
                v-if="tab === 'SGJ9' || tab === 'SGJ8'"
                color="primary"
                :href="tab === 'SGJ9' ? 'https://www.matsulab.org/#/event' : 'http://www.mediadesignlabs.org/SGJ8/'"
                target="_blank"
                class="mb-2"
              >
                {{ tab }}公式サイトへ
              </v-btn>
              <v-responsive v-if="tab !== 'SGJ9'" aspect-ratio="16/9" class="mt-2">
                <iframe 
                  width="100%" 
                  height="100%" 
                  :src="videoUrls[tab]" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                ></iframe>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>

  <v-card class="pa-4 mb-4" outlined>
                    <v-card-title class="text-h5 primary--text">
                      <v-icon large color="primary" class="mr-2">mdi-help-circle</v-icon>
                      質問フォーム
                    </v-card-title>
                    <!-- 質問フォームの内容 -->
                    <v-card-text>
  <p>ご質問等ございましたら、下記質問フォームよりお願いします。</p>
  <v-btn
    color="secondary"
    href="https://docs.google.com/forms/d/e/1FAIpQLSfhtOV8-qY7dwTINnDF4SqZmobU79EI1vvjrl3IxhQKCcT54A/viewform?usp=sf_link/"
    target="_blank"
    class="mt-2"
  >
    質問フォーム
  </v-btn>
</v-card-text>
                  </v-card>
                  
                </v-col>
              </v-row>

              <v-card class="pa-4 mb-6" outlined>
                <v-card-title class="text-h5 primary--text">
                  <v-icon large color="primary" class="mr-2">mdi-account-group</v-icon>
                  専門家
                </v-card-title>
                <!-- 専門家の内容 -->
                <v-card-text>
  <v-row>
    <v-col v-for="expert in experts" :key="expert.name" cols="12" sm="6" md="4">
      <v-card outlined height="100%">
        <v-img
          :src="expert.image"
          height="200"
          contain
          class="bg-grey lighten-2"
        ></v-img>
        <v-card-title class="text-subtitle-1">{{ expert.name }}</v-card-title>
        <v-card-subtitle>{{ expert.title }}</v-card-subtitle>
        <v-card-text class="text-caption">{{ expert.description }}</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-card-text>
              </v-card>

              <v-card class="pa-4 mb-4" outlined>
    <v-card-title class="text-h5 primary--text">
      <v-icon large color="primary" class="mr-2">mdi-account-group</v-icon>
      実行委員
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="member in committeeWithImages" :key="member.name" cols="12" md="6">
          <v-card outlined>
            <v-row no-gutters>
              <v-col cols="4">
                <v-img :src="member.image" height="150" cover></v-img>
              </v-col>
              <v-col cols="8">
                <v-card-title>{{ member.name }}</v-card-title>
                <v-card-subtitle>{{ member.title }}</v-card-subtitle>
                <v-card-text>{{ member.description }}</v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row dense>
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              <v-chip-group column>
                <v-chip v-for="member in committeeTextOnly" :key="member.name" small outlined>
                  {{ member.name }} ({{ member.description }})
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

              <v-card class="pa-4" outlined>
                <v-card-title class="text-h5 primary--text">
                  <v-icon large color="primary" class="mr-2">mdi-map-marker</v-icon>
                  会場案内
                </v-card-title>
                <v-card-text>
                  <div class="map-responsive">
                    <iframe 
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.163991361167!2d139.7623194748807!3d35.69758187258197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d21e7b9bdef%3A0x86db543802a3a502!2z44K_44Ov44O844O744K544Kz44Op!5e0!3m2!1sja!2sjp!4v1700053792966!5m2!1sja!2sjp" 
                      width="600" 
                      height="450" 
                      style="border:0;" 
                      allowfullscreen="" 
                      loading="lazy" 
                      referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                  </div>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-btn
      v-show="showFloatingButton"
      color="primary"
      href="https://docs.google.com/forms/d/e/1FAIpQLSdJBoMWQe0cG7QK1Y1tx4JFLMHCLjmS5c8QiX_mdmujUr5laA/viewform?usp=sf_link"
      target="_blank"
      fixed
      bottom
      right
      class="floating-apply-btn"
    >
      <v-icon left>mdi-pencil</v-icon>
      参加申し込み
    </v-btn>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showFloatingButton: false,
      activeTab: 0,
      tabs: ['SGJ9', 'SGJ8', 'SGJ7', 'SGJ6'],
      videoUrls: {
        SGJ8: 'https://www.youtube.com/embed/D5LCmr_8P1Q',
        SGJ7: 'https://www.youtube.com/embed/WRztX92B78o',
        SGJ6: 'https://www.youtube.com/embed/Q8c5vio1JV0'
      },
      list: [
        {
          img: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/SGJ10-homepage-key-V011-suuri.jpg",
        },
      ],
      experts: [
        {
          name: "島崎 敢",
          title: "近畿大学生物理工学部，准教授",
          image: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Shimazaki.JPG",
          description: "早稲田大学，国立研究開発法人防災科学技術研究所，名古屋大学を経て2022年から現職。災害や事故のリスクを減らし，安全のための行動を増やす研究している心理学者。",
        },
        {
          name: "栗山 章",
          title: "アールシーソリューション株式会社，代表",
          image: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Kuriyama.jpg",
          description: "1987年からシステムエンジニアとして従事。2002年、アールシーソリューション株式会社を設立。2007年，緊急地震速報の事業化を機に防災に携わる。",
        },
        {
          name: "古市 昌一",
          title: "日本大学生産工学部，教授",
          image: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Ichikawa.jpeg",
          description: "シリアスゲームの構築法に関する研究者。シリアスゲームを開発する際に重要な要素について講義を行う。",
        },
      ],
      committee: [
        {
          name: "松野裕",
          title: "実行委員長",
          image: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Matsuno.jpeg",
          description: "日本大学理工学部応用情報工学科の松野です。ソフトウェア工学の観点から、学生を中心としたソフトウェア開発について研究しています。",
        },
        {
          name: "粟飯原萌",
          title: "副実行委員長",
          image: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Aibara.jpeg",
          description: "これまで同テーマで実施したSGJ 2からSGJ8に関わった経験から，ゲームの力は社会の様々な問題解決を行うのにとても有効な手段であると感じました。",
        },
        {
          name: "島崎敢",
          title: "実行委員",
          description: "近畿大学",
        },
        {
          name: "栗山章",
          title: "実行委員",
          description: "アールシーソリューション株式会社",
        },
        {
          name: "古市昌一",
          title: "実行委員",
          description: "日本大学",
        },
        {
          name: "高澤有以子",
          title: "実行委員",
          description: "青山学院大学",
        },
        {
          name: "吉田寛",
          title: "実行委員",
          description: "東京大学",
        },
        {
          name: "駒田智彦",
          title: "実行委員",
          description: "日本大学",
        },
        {
          name: "櫨山章太郎",
          title: "実行委員",
          description: "日本大学",
        },
        {
          name: "羽山夏生",
          title: "実行委員",
          description: "日本大学",
        },
        {
          name: "永井碧人",
          title: "実行委員",
          description: "日本大学",
        },
      ],
    };
  },
  computed: {
    committeeWithImages() {
      return this.committee.filter(member => member.image);
    },
    committeeTextOnly() {
      return this.committee.filter(member => !member.image);
    }
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          this.showFloatingButton = !entry.isIntersecting;
        });
      }, options);

      observer.observe(this.$refs.originalApplyBtn.$el);
    },
    handleTabChange(newTab) {
      this.$nextTick(() => {
        this.scrollToActiveTab();
      });
    },
    scrollToActiveTab() {
      const container = this.$refs.tabsContainer;
      const activeTab = container.querySelector('.v-tab--active');
      if (activeTab) {
        const containerWidth = container.offsetWidth;
        const tabWidth = activeTab.offsetWidth;
        const tabLeft = activeTab.offsetLeft;
        const targetScrollLeft = tabLeft - (containerWidth - tabWidth) / 2;
        
        container.scrollTo({
          left: targetScrollLeft,
          behavior: 'smooth'
        });
      }
    },
    navigateTab(direction) {
      if (direction === 'prev' && this.activeTab > 0) {
        this.activeTab--;
      } else if (direction === 'next' && this.activeTab < this.tabs.length - 1) {
        this.activeTab++;
      }
      this.$nextTick(() => {
        this.scrollToActiveTab();
      });
    }
  },
  mounted() {
    this.setupIntersectionObserver();
    this.$nextTick(() => {
      const leftArrow = this.$el.querySelector('.v-tabs__wrapper > .v-slide-group__prev');
      const rightArrow = this.$el.querySelector('.v-tabs__wrapper > .v-slide-group__next');

      if (leftArrow) {
        leftArrow.addEventListener('click', (event) => {
          event.stopPropagation(); // イベントの伝播を止める
          this.navigateTab('prev');
        });
      }

      if (rightArrow) {
        rightArrow.addEventListener('click', (event) => {
          event.stopPropagation(); // イベントの伝播を止める
          this.navigateTab('next');
        });
      }
    });
  }
};
</script>

<style scoped>
.custom-main-padding {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

@media (min-width: 960px) {
  .custom-main-padding {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
}

/* v-cardのマージンも調整 */
.v-card {
  margin-top: 16px !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 アスペクト比 */
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 960px) {
  .v-card {
    margin-top: 24px !important;
  }
}

/* タイトルのセンタリングを確実にする */
.v-card-title {
  display: block;
  width: 100%;
}
.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%; /* 16:9 アスペクト比 */
  position: relative;
  height: 0;
}
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.wrap-text {
  white-space: normal;
  word-break: break-word;
}
.custom-tabs-container {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.custom-tabs-container::-webkit-scrollbar {
  height: 8px;
}

.custom-tabs-container::-webkit-scrollbar-track {
  background: transparent;
}

.custom-tabs-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

@media (hover: none) and (pointer: coarse) {
  .custom-tabs-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.custom-apply-btn {
  font-size: 1.2rem !important;
  padding: 12px 24px !important;
  height: auto !important;
}

.custom-btn-text {
  font-weight: bold;
}

.floating-apply-btn {
  bottom: 20px !important;
  right: 20px !important;
  padding: 0 20px !important;
  height: 48px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: none !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2) !important;
  z-index: 9999 !important;
}

/* モバイル向けスタイル調整 */
@media (max-width: 600px) {
  .custom-apply-btn {
    font-size: 1.1rem !important;
    padding: 10px 20px !important;
  }

  .floating-apply-btn {
    bottom: 10px !important;
    right: 10px !important;
    font-size: 14px !important;
    padding: 0 15px !important;
    height: 40px !important;
  }
}

</style>