<template>
  <main>
    <div class="container">
      <!-- iframeのkey属性にiframeKeyをバインド -->
      <iframe v-if="file" :src="item" :key="iframeKey" width="100%" height="200%"></iframe>
      <v-main v-if="!file" class="pa-0">
        <v-container>
          <v-col cols="8">
            <p v-html="item"></p>
          </v-col>
        </v-container>
      </v-main>
    </div>
  </main>
</template>

<script>
import { getItem } from '@/plugins/firebase';
export default {
  props: ['id'],
  data() {
    return {
      item: '',
      list: [],
      file: false,
      iframeKey: 0,  // iframeを再描画するためのキー
    };
  },
  methods: {
    handleResize() {
      // ウィンドウのリサイズイベントが発生するたびにiframeKeyを更新
      this.iframeKey++;
    }
  },
  async created() {
    window.addEventListener('resize', this.handleResize);  // リサイズイベントリスナーを追加
    this.list = await getItem('project');
    const result = this.list.find((u) => u.id == this.id);
    if (result.mode == 'file') {
      this.file = true;
      this.item = result.url;
    } else if (result.mode == 'page') {
      this.item = result.url;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);  // リスナーを削除
  },
};
</script>

<style>
iframe[src$='.pdf'] {
  width: 100%;
  height: 80vh;
}
</style>
