<template>
<v-app>
<Top />
  <v-col align="center">
    <div style="display: flex; justify-content: space-evenly;">
        <div><router-link to="/event">SGJ9</router-link></div>
        <div><router-link to="/event/SGJ10">SGJ10</router-link></div>
      </div>
  <div style="position:absolute; color:orangered; left: 0; right: 0; margin: auto; pointer-events:none;">
    <h2>Participation is free</h2>
  </div>
  <p>
    <router-link to="/event">JAPANESE</router-link>
  </p>
    <v-container v-for="(src, index) in list" :key="index">
      <img
        class="alignleft size-thumbnail wp-image-583"
        alt=""
        :src="src.img"
      />
    </v-container>
    <div id="app"> 
      <v-main class="pa-0">
      <GJSidebar style="pointer-events:auto"></GJSidebar>
        <v-container>
            <v-col cols="10">
            <h1>The 9th Serious Game Jam
            </h1>
            <v-spacer />
            <h3>
              〜Disaster Prevention Games for Preparedness〜
            </h3>
            <v-spacer />
            <v-sheet class="px-6" align="left">
              Preparation for disasters extends beyond stockpiling essentials like water and food. Understanding where and how disasters can occur, anticipating specific challenges based on location and time of occurrence, knowing what actions are necessary, identifying relevant information, people, and systems that can offer assistance—imagining, gathering information, and taking proactive steps can significantly enhance disaster preparedness.<br>
              However, many individuals are not adequately prepared. Consequently, in the event of a disaster, there is widespread confusion and panic.<br>
              Therefore, we encourage the creation of games that not only prompt players to think about preparedness but also naturally instill a sense of readiness through gameplay. Games have the power to increase preparedness, and by developing games that inspire a proactive mindset, we aim to elevate everyone's resilience.<br>
              Let's use the power of games to boost preparedness and enhance everyone's disaster resilience.<br><br>
            
            </v-sheet>
            <v-spacer />
            <v-divider color="black"></v-divider>
        <div class="Yoko">  
          <div class="contents-box"> 
            <h2>Event Overview</h2>
            <p>
            The Serious Game Jam is an event aimed at solving various societal challenges through the power of games, 
            bringing together experts, game developers, and students to collaborate and create serious games in a short period. 
            The purpose of this event is to enhance awareness of disaster preparedness and convey the importance of being prepared in an active and engaging way.
            </p><br>
            <h3 align="left">Date and Time</h3>
            <p>
              March 5, 2024(Tuesday)9:30 ~ 18:00: Theme explanation, Team formation, Production begins.<br>
              March 6, 2024(Wednesday)9:00 ~ March 7, 2024(Thursday)18:00: Production, Presentation, etc.
            </p><br>
            <h3 align="left">Venue</h3>
            <p>
              3-11-2 Kanda Surugadai, Chiyoda-ku, Tokyo 101-0062, Japan<br>
              Nihon University, Faculty of Science and Engineering, Surugadai Campus, Tower Scola 5th floor, Room S504 and S505
            </p>
            <v-card class=”google_map” max-width="98%">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.163991361167!2d139.7623194748807!3d35.69758187258197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d21e7b9bdef%3A0x86db543802a3a502!2z44K_44Ov44O844O744K544Kz44Op!5e0!3m2!1sja!2sjp!4v1700053792966!5m2!1sja!2sjp" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </v-card><br><br>

            <h3 align="left">Accommodation facilities nearby</h3>
            <p>
            <a href="https://www.apahotel.com/hotel/syutoken/tokyo/kanda-jimbocho-ekihigashi/?utm_source=google&utm_medium=maps"　target="_blank">APA Hotel (Kanda Jimbo-cho Station East)</a>/One night rate:¥10,000<br>
            <a href="https://mangaarthotel.com/maht/index-en.html"　target="_blank">MANGA ART HOTEL</a>/One night rate:¥3,100<br>
            <a href="https://www.familyhotel.jp/"　target="_blank">OCHANOMIZU SHORYUKAN</a>/One night rate:¥8,800<br>
            </p><br>

            <h3 align="left">Application for participation, etc</h3>
            <p>

              The participation will be confirmed once you respond to the following registration and complete the pre-disaster awareness survey sent via your email.
            
            </p>
            <h1><a href="https://docs.google.com/forms/d/e/1FAIpQLScxzRluzR53p6nt2ykn0BTnvC0kkvM60AqGrwIXF9BdEdsIWg/viewform?usp=sf_link"　target="_blank">Application for participation</a></h1><br>
            <h3 align="left">Participation Fee</h3>
            <p>
              Free
            </p><br>
            
            <h3 align="left">Executive Committee</h3>
            <p>
              Chair of the Executive Committee:Yutaka Matsuno(<a href="http://www.ce.cst.nihon-u.ac.jp/"　target="_blank">Department of Computer Engineering, Science and Technology, Nihon Univ.</a><br>,<a href="https://www.runit.cst.nihon-u.ac.jp/nuds/"　target="_blank">Nihon University Disaster ReseachSociety(NUDS)</a>)<br>
              Vice Chair of the Executive Committee:Megumi Aibara(<a href="https://www.eme.cst.nihon-u.ac.jp/"　target="_blank">Department of Precision Machinery Engineering, Science and Technology, Nihon Univ.</a><br>,<a href="https://www.runit.cst.nihon-u.ac.jp/nuds/"　target="_blank">Nihon University Disaster ReseachSociety(NUDS)</a>)<br><br>
              Executive Committee Member:<br>
              Kan Shimazaki(Kinki Univ.)<br>
              Akira Kuriyama(RC Solution Co., Ltd.,)<br>
              Masakazu Huruichi(Nihon Univ.)<br>
              Yuiko Takazawa(Aoyama Gakuin Univ.)<br>
              Shotaro Hazeyama(Nihon Univ.)<br>
              Natsuki Hayama(Nihon Univ.)<br>
              Aoto Nagai(Nihon Univ.)<br>
            </p><br>
            <h3 align="left">Support</h3>
            <p>
              Educational Special Interest Group of DiGRA JAPAN <br>
              RC Solution Co., Ltd.,
            </p><br>
            <v-divider></v-divider>
          </div>  
          <v-divider color="black" vertical></v-divider>

          <div class="contents-box">
            <h2>Characteristics of Serious Game Jams.</h2>
            <ol>
              <li align="left">Create a serious game by selecting a societal issue as the theme and addressing it.</li>
              <li align="left">Implementation of instructions from serious game experts on the production procedure and theme-related guidance.</li>
              <li align="left">Introducing the role of a "Researcher" in addition to game developers allows individuals outside the game development field to contribute to the game creation process.</li>
            </ol>
            <p>
              <span></span>Furthermore, for this Serious Game Jam, the development at the venue will be conducted from 9:00 AM to 7:00 PM, taking into consideration working hours.<br>
            </p><br>
            <v-divider/>
            <h2>The flow of a game jam.</h2>
            <p>
              March 5(Tuesday),9:30AM-7:00PM.<br>
              Assembly.<br>
              <span></span>↓<br>
              Explanation of how to create serious games and themes.<br>
              <span></span>↓<br>
              Team building (formation of teams of 5 people each).<br>
              In a game jam, the main roles include team leader, game designer, programmer, designer (2D/3D/animation, etc.), sound/music, and research. Due to limited numbers, one person may take on multiple roles.<br>
              <span></span>Bringing your portfolio or other materials when you introduce yourself will give you an edge in your appeal.<br>
              <span></span>↓<br>
              Game design and development begins!(Classrooms available until 7pm)<br>
              March 6(Wednesday)9:00-, March 7(Thursday)18:00<br>
              <span></span>Immediately after the start of the event, participants are given a variety of missions(tasks) by the committee members. Each mission has a deadline and is scored for use in the final evaluation.<br>
              <span></span>The mission is a milestone in the creation of a serious game. It includes a midterm presentation, having an expert on the theme review the game design, and more.<br>
              <span></span>Work will be available until 19:30 on the evening of Wednesday the 6th, and admission will open at 9:30 on Thursday 7th.<br>
            </p><br>
            <v-divider></v-divider>
          </div> 
        </div><br>
 
        <h2>Specialists</h2> 
        <div class="Yoko">    
          <div class="contents-box" style="background:ghostwhite">
            <div class="Exp">
              <h3>Kan Shimazaki<br>(Associate Professor, Faculty of Biology-Oriented Science and Technology, Kinki Univ.)</h3>
            </div>
            <img
               src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Shimazaki.JPG"
            />
            <p>
              "I am a psychologist with a background from Waseda University, the National Research Institute for Earth Science and Disaster Resilience, and Nagoya University. <br>
              Since 2022, I have been researching ways to reduce disaster and accident risks while promoting safety behaviors. I've supported local disaster response during events like the Kumamoto earthquake and western Japan heavy rainfall. Outside of crises, I contribute to nationwide disaster education. My hobbies include cooking and free diving, and I'm also a former truck driver."
            </p>
          </div> 
          <div class="contents-box" style="background:ghostwhite">
            <div class="Exp">
              <h3>Akira Kuriyama<br>(RC Solution Co., Ltd., Representative)</h3>
            </div>
            <img
            src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Kuriyama.jpg"
            />
            <p>
              "From 1987, as a system engineer, he was engaged in the development of telecommunication systems such as multiplex transmission systems and telegraph systems, and financial systems such as accounting systems and exchange trading systems.<br>
              In 2002, he established RC Solution Corporation and became its president. 2007, he became involved in disaster prevention when he commercialized the Earthquake Early Warning System.<br>
              Born in Niigata, Japan. Hobbies: Drinking and reading."
            </p>
          </div> 
          
          <div class="contents-box" style="background:ghostwhite">
            <div class="Exp">
              <h3>Masakazu Huruichi<br>(Professor, Faculty of Industrial Science and Technology, Nihon Univ.)</h3>
            </div>
            <img
            src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Ichikawa.jpeg"
            />
            <p>
            ”As a researcher specializing in the construction of serious games, you will be giving a lecture to the participants who are creating serious games for the first time. The lecture will focus on the crucial aspects of developing serious games, including (1) clarification of what users demand, (2) methods for verifying effectiveness, and (3) theories of game enjoyment."
            </p>
          </div> 
        </div>
 
        <v-divider/>
        <h2>Executive committee</h2>
        <div class="Tate">
          <div class="Zikko">

            <div class="Zikkoimg">
              <img
              src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Matsuno.jpeg"
              />
              <h3>Yutaka Matsuno</h3>
            </div>
            <div class="Zikkoword px-6">
              <p>
                My name is Matsuno from the Department of Applied Information Engineering, College of Science and Technology, Nihon University.<br>
                At Nihon University, we are researching software development from the perspective of software engineering, with a focus on students.<br>
                We hope to develop various disaster prevention applications based on the ideas generated by this disaster prevention game jam.<br>
                We encourage students and working people who are interested in games, design, and disaster prevention to participate in this event, even if they have no experience in software development.
              </p>
            </div>
          </div>
          <div class="Zikko">
            <div class="Zikkoimg">
              <img
              src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Aibara.jpeg"
              />
              <h3>Megumi Aibara</h3>
            </div>
            <div class="Zikkoword px-6">
              <p>
                I am Awaibara from the Department of Precision Mechanical Engineering, College of Science and Technology, Nihon University.<br>
                From my experience with SGJ 2 through SGJ8, which have been held under the same theme, I have found the power of games to be a very effective tool for solving various problems in society. <br>
                At the Serious Game Jam, we also prepare time to teach participants how to make serious games and about the theme.<br>
                Whether you have developed games before or are a first-timer, we look forward to your participation.<br>
                Let's save the world together with the power of games!！
              </p>
            </div>
          </div>
        </div>
        <v-divider/> 
            
        </v-col>
        </v-container>
      </v-main>
    </div>
    
    </v-col>
</v-app>
</template>


<script>
import GJSidebar from "@/components/bar/GJSidebar";

export default {

  components: {
    GJSidebar,
  },
  data() {
    return {
      list: [],
    };
  },
  async created() {
    this.list = [
      {
        img:
          "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/SGJ9-homepage-key-V5.png",          
      },
    ];
  },
  methods: {},
  computed: {
  },
};
</script>

<style scoped>


.Yoko {
  margin-top:0px;
  display: flex;   
  justify-content:center;
}
.Tate {
  margin-top:0px;  
  justify-content:center;
}
@media screen and (max-width: 770px) {
  .Yoko {
    flex-direction: column;
  }
  .Zikko{
    flex-direction: column;
  }
  .Zikkoimg{
    max-width: initial;
  }
}
@media screen and (min-width: 771px) {
  .Zikkoimg{
    max-width: 150px;
  }
  .Exp{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:255px;
  } 
}
@media screen and (min-width: 814px) {
.Exp{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:200px;
  } 
}
@media screen and (min-width: 1264px) {
  .Exp{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:120px;
  }
}

.contents-box{
  background-color:white;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:24px;
  padding-right:24px;
  margin:3px;
  position:relative;
  flex:1;
}
.contents-img{
  width:80%;
  height:200px;
}
.Zikko{
  background-color:lightgray;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  position:relative;
  margin:5px;
}
.Zikkoimg{
  background-color:lightgray;
  flex:1;
  flex-basis: auto;
}
.Zikkoword{
  background-color:ghostwhite;
  padding:2%;
  flex-basis: auto;
  flex:1;
}
img {
  width: 100%;
  max-width: 900px;
}

span {
  margin-left: 1rem;
}

p{
  margin:0;
  padding:0;
  text-align:left;
}


</style>