import { initializeApp } from 'firebase/app';
import { getFirestore, orderBy, query } from 'firebase/firestore';
import { collection, getDocs, deleteDoc } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

function config() {
  const firebaseConfig = {
    apiKey: 'AIzaSyBFPN07M7-CuLL2rcIREIyvk_PFM52s05c',
    authDomain: 'mlab-homepage-evacuation.firebaseapp.com',
    projectId: 'mlab-homepage-evacuation',
    storageBucket: 'mlab-homepage-evacuation.appspot.com',
    messagingSenderId: '782553978195',
    appId: '1:782553978195:web:6c83be56f6d03cb6e0bda6',
    measurementId: 'G-8BGL1GKZ17',
  };
  return initializeApp(firebaseConfig);
}

// Initialize Firebase
export async function getItem(val) {
  let cache = sessionStorage.getItem(val); //キャッシュから取得
  if (cache) {
    //nullの区別
    cache = JSON.parse(cache);
    if (cache) {
      //中身があるかどうかの確認
      return cache;
    }
  }
  let array = [];
  console.log('get');
  const db = getFirestore(config());
  let querySnapshot = [];
  switch (val) {
    case 'topic':
      querySnapshot = await getDocs(query(collection(db, val), orderBy('time', 'desc')));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        array.push(data);
      });
      break;
    case 'profile':
      querySnapshot = await getDocs(query(collection(db, val), orderBy('name')));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        array.push(data);
      });
      console.log(array);
      break;
    default:
      querySnapshot = await getDocs(query(collection(db, val)));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        array.push(data);
      });
  }
  /*
  if(val =="project")
  const project = {research:[],
  seminar: []
  }
  */
  let json = JSON.stringify(array, undefined, 1);
  sessionStorage.setItem(val, json);
  return array;
}
/*
val:投稿先 トピック, プロジェクト等
item:投稿したい配列
mode:新規か編集か
*/
export async function postItem(val, item, mode) {
  const db = getFirestore(config());
  if (mode == 'new') {
    const newid = doc(collection(db, val));
    item.id = newid;
    await setDoc(newid, item);
    sessionStorage.removeItem(val);
  } else if (mode == 'update') {
    try {
      await setDoc(doc(db, val, item.id), item, { merge: true });
    } catch (err) {
      console.log(`Error: ${JSON.stringify(err)}`);
    }
  }
  sessionStorage.removeItem(val);
}

export async function uploadFile(item, type) {
  const storage = getStorage(config());
  const path = type + '/' + item.name;
  const storageRef = ref(storage, path);
  await uploadBytes(storageRef, item).then(() => {
    window.alert(item.name + 'をアップロードしました');
  });
  return 'https://storage.googleapis.com/mlab-homepage-evacuation.appspot.com/' + path;
}

export async function deleteItem(val, item) {
  const db = getFirestore(config());
  await deleteDoc(doc(db, val, item.id));
  sessionStorage.removeItem(val);
}
