<template>
<v-app>
<Top />
  <v-col align="center">
    <div style="display: flex; justify-content: space-evenly;">
        <div><router-link to="/event/eventENG">ENGLISH</router-link></div>
        <div><router-link to="/event/SGJ10">SGJ10</router-link></div>
      </div>
    <v-container v-for="(src, index) in list" :key="index">
      <img
        class="alignleft size-thumbnail wp-image-583"
        alt=""
        :src="src.img"
      />
    </v-container>
    
    <div id="app"> 
      <v-main class="pa-0">
        <v-container>
            <v-col cols="10">
            <h1>第9回シリアスゲームジャム
            </h1>
            <v-spacer />
            <h2>The 9th Serious Game Jam</h2>
            <h1><font color=red>開催終了いたしました。<br>たくさんのご応募ありがとうございました。</font></h1>
           
            <GJSidebar style="pointer-events:auto"></GJSidebar>
            <h3 align="center">
              〜備えたくなる防災ゲーム〜
            </h3>
            <v-spacer />
            <v-sheet class="px-6" align="left">
              災害に対する「備え」は，水や食料などの備蓄だけではありません．災害はどこでどのように起き，何に困るのか，災害にあう場所や時間によってどんな対処が必要なのか，どんな情報や人や制度が自分を助けてくれるのか，想像し，情報収集し，できることをやっておけば，防災力は格段に上がります．
              しかし，多くの人は「備え」をしていません．だから明日災害がくればみんな右往左往してしまいます．<br><br>
              <b>そこで，プレイした人が「備えよう」と思ってくれるゲームや，プレイするだけで自然に備えができるようなゲームを期待します</b>．<br>
              ゲームの力で「備え」を増やし，みんなの防災力を高めましょう．<br><br>
            
            </v-sheet>
            <v-spacer />
            <v-divider color="black"></v-divider>
        <div class="Yoko">  
          <div class="contents-box"> 
            <h2>開催概要</h2>
            <p>
            シリアスゲームジャムとは，社会における様々な課題をゲームのチカラで解決することを目的とし，
            専門家とゲーム開発者と学生らが力を結集して短期間でシリアスゲームを制作するイベントです．
            防災に関する認識を高め，備えることの重要性をアクティブでエンゲージングな方法で伝えることを目的としています．
            ゲームは情報を楽しく，分かりやすく伝える手段として非常に効果的であり,このイベントを通じて新たな防災意識を広めることを期待しています．
            </p><br>
            
            <h3 align="left">日時</h3>
            <p>
              2024年3月5日(火)9:30〜18:00 -テーマ説明，チーム編成, 制作開始-<br>
              2024年3月6日(水)9:00〜，7日(木)18:00 -制作，発表会等-
            </p><br>

            <h3 align="left">会場</h3>
            <p>
              〒101-0062<br>
              東京都千代田区神田駿河台３丁目１１−２<br>
              日本大学 理工学部 駿河台キャンパス タワースコラ5階 S504 および S505教室
            </p>
            <v-card class=”google_map” max-width="98%">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.163991361167!2d139.7623194748807!3d35.69758187258197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d21e7b9bdef%3A0x86db543802a3a502!2z44K_44Ov44O844O744K544Kz44Op!5e0!3m2!1sja!2sjp!4v1700053792966!5m2!1sja!2sjp" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </v-card><br><br>

            <h3 align="left">周辺の宿泊施設</h3>
            <p>
            今回のゲームジャムは夜間に会場を使用することができません．遠方からお越しの場合，ご自身で宿泊先を予約してもらう必要があります．<br>
            <a href="https://www.apahotel.com/hotel/syutoken/tokyo/kanda-jimbocho-ekihigashi/?utm_source=google&utm_medium=maps"　target="_blank">アパホテル・神田神保町駅東</a>/1泊:¥10,000~<br>
            <a href="https://mangaarthotel.com/maht/index-en.html"　target="_blank">MANGA ART HOTEL</a>/1泊:¥3,100~<br>
            <a href="https://www.familyhotel.jp/"　target="_blank">昇龍館</a>/1泊:¥8,800~<br>
            </p><br>

            <h3 align="left">参加申し込み等</h3>
            <p>
              定員に達しましたので，参加申し込みは締め切りました．
            </p>
            <h1><a href="https://docs.google.com/forms/d/e/1FAIpQLScxzRluzR53p6nt2ykn0BTnvC0kkvM60AqGrwIXF9BdEdsIWg/viewform?usp=sf_link"　target="_blank">参加申し込み</a></h1><br>

            <p>
              また，ジャムには参加せずに見学のみの参加も申し込みも受け付けております．
            </p>
            <h2><a href="https://forms.gle/usLUk9MFX14AYZ7h7"　target="_blank">見学申し込み</a></h2><br>
            <h3 align="left">参加費/見学料</h3>
            <p>
              無料
            </p><br>
            
            <h3 align="left">SGJ9実行委員会</h3>
            <p>
              実行委員長:松野裕(<a href="http://www.ce.cst.nihon-u.ac.jp/"　target="_blank">日本大学理工学部応用情報工学科</a><br>，<a href="https://www.runit.cst.nihon-u.ac.jp/nuds/"　target="_blank">日本大学災害研究ソサエティ(NUDS)</a>)<br>
              副実行委員長:粟飯原萌(<a href="https://www.eme.cst.nihon-u.ac.jp/"　target="_blank">日本大学理工学部精密機械工学科</a><br>，<a href="https://www.runit.cst.nihon-u.ac.jp/nuds/"　target="_blank">日本大学災害研究ソサエティ(NUDS)</a>)<br>
              実行委員:<br>
              島崎敢(近畿大学)<br>
              栗山章(アールシーソリューション株式会社)<br>
              古市昌一(日本大学)<br>
              高澤有以子(青山学院大学)<br>
              櫨山章太郎(日本大学)<br>
              羽山夏生(日本大学)<br>
              永井碧人(日本大学)<br>


            </p><br>
            <h3 align="left">スポンサー</h3>
            <p>
              アールシーソリューション株式会社<br>
              日本大学生産工学部校友会 数理情報工学部会<br>
              コロナ社<br>
              エムエスアイコンピュータージャパン株式会社<br>
              MatsuSoft LLC<br>
              (株)東京技術計算コンサルタント<br>
              TANOTECH株式会社<br>
              ライオン株式会社
            </p>
            <br>
            <h3 align="left">後援</h3>
            <p>
              日本デジタルゲーム学会教育SIG<br>
              東京大学文学部美学芸術学研究室・東京大学ゲーム研究室（吉田寛研究室）<br>
              HAMONI<br>
              日本ソフトウェア科学会ディペンダブルシステム研究会(DSW)<br>
            </p>
            <br>
            <v-divider></v-divider>
          </div>
          <v-divider color="black" vertical></v-divider>

          <div class="contents-box">
            <h2>シリアスゲームジャムの特徴</h2>
            <ol>
              <li align="left">世の中の課題をテーマとして選出し，シリアスゲームを作成する</li>
              <li align="left">シリアスゲームの専門家から制作手順とテーマ関する専門家による教示の実施</li>
              <li align="left">ゲーム開発者以外の役職として「リサーチャー」を設け，ゲーム開発者以外もゲーム開発に関われる役職がある</li>
            </ol>
            <p>
              <span></span>また，今回のシリアスゲームジャムは，会場での開発は朝9時〜19時までとして働き方を考慮した時間で開催します．<br>
            </p><br>
            <v-divider/>
            <h2>ゲームジャムの流れ</h2>
            <p>
              3月5日(火)9:30-19:00<br>
              集合<br>
              <span></span>↓<br>
              シリアスゲームの作成方法とテーマに関する説明<br>
              <span></span>↓<br>
              チームビルディング（５人１組のチーム編成）<br>
              ゲームジャムでの主な役職は，チームリーダ，ゲームデザイナ，プログラマ，デザイナ(2D/3D/アニメーション等)，サウンド／音楽，リサーチ担当です．人数に限りがあるので一人が複数の役職を担当します．<br>
              <span></span>自己紹介の時にポートフォリオ等を持参するとアピールに優位です<br>
              <span></span>↓<br>
              ゲームデザインと開発を開始！　(19時まで教室の利用が可能)<br>
              3月6日(水)9:00〜，7日(木)18:00<br>
              <span></span>開始直後から，参加者の皆さんには様々なミッション（課題）が実行委員から与えられます．各ミッションには締め切り時間があり，点数化されて最終評価で用いられます．<br>
              <span></span>ミッションは，シリアスゲームを制作するためのマイルストーンです．中間発表，テーマに関する専門家にゲームデザインを確認していただく等が盛り込まれています．<br>
              <span></span>6日(水)の晩は19:30まで作業が可能で，7日(木)は9:30から入場可能です．<br>
            </p>  
            <br>
            <v-divider></v-divider>
          </div> 
        </div><br>
 
        <h2>専門家</h2>  
        <div class="Yoko">    
          <div class="contents-box" style="background:ghostwhite">
            <div class="Exp">
              <h3>島崎 敢<br>近畿大学生物理工学部，准教授</h3>
            </div>
            <img
               src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Shimazaki.JPG"
            />
            <p>
              早稲田大学，国立研究開発法人防災科学技術研究所，名古屋大学を経て2022年から現職．災害や事故のリスクを減らし，安全のための行動を増やす研究している心理学者．熊本地震や西日本豪雨等にける現地災対本部の支援や，平時における全国各地の防災教育支援経験を持つ．<br>
              趣味は料理と素潜り．元トラックドライバー．
            </p>
          </div> 
          <div class="contents-box" style="background:ghostwhite">
            <div class="Exp">
              <h3>栗山 章<br>アールシーソリューション株式会社，代表</h3>
            </div>
            <img
            src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Kuriyama.jpg"
            />
            <p>
            1987年からシステムエンジニアとして，多重化伝送システム，電報システム等の通信システムや，勘定システム，為替取引システム等の金融システムの開発に従事．<br>
            2002年，アールシーソリューション株式会社を設立，代表取締役に就任．2007年，緊急地震速報の事業化を機に防災に携わる．<br>
            出身地，新潟．趣味，お酒を飲みながら読書．
            </p>
          </div> 
          <div class="contents-box" style="background:ghostwhite">
            <div class="Exp">
              <h3>古市 昌一<br>日本大学生産工学部，<br>教授</h3>
            </div>
            <img
            src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Ichikawa.jpeg"
            />
            <p>
            シリアスゲームの構築法に関する研究者で，初めてシリアスゲームを制作する参加者の皆さんに向けて，シリアスゲームを開発する際に重要な（１）ユーザが求めるものの明確化，（２）効果の検証法，（３）ゲームの面白さの理論等について講義をしていただきます．
            </p>
          </div> 
        </div>
        <v-divider/>

        <h2>実行委員</h2>
        <div class="Tate">
          <div class="Zikko">

            <div class="Zikkoimg">
              <img
              src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Matsuno.jpeg"
              />
              <h3>松野裕</h3>
            </div>
            <div class="Zikkoword px-6">
              <p>
                日本大学理工学部応用情報工学科の松野です．<br>
                日本大学では，ソフトウェア工学の観点から，学生を中心としたソフトウェア開発について研究しています．<br>
                今回の防災ゲームジャムで生まれるアイデアをもとに，様々な防災アプリを展開していけたらと思います．<br>
                ソフトウェア開発の経験がなくても，ゲームや，デザイン，防災に興味のある学生，社会人の方のご参加をぜひお願い致します．
              </p>
            </div>
          </div>
          <div class="Zikko">
            <div class="Zikkoimg">
              <img
              src="https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/Jam_Aibara.jpeg"
              />
              <h3>粟飯原萌</h3>
            </div>
            <div class="Zikkoword px-6">
              <p>
                日本大学理工学部精密機械工学科の粟飯原です．<br>
                これまで同テーマで実施したSGJ 2からSGJ8に関わった経験から，ゲームの力は社会の様々な問題解決を行うのにとても有効な手段であると感じました．<br>
                シリアスゲームジャムでは，シリアスゲームの作り方やテーマについて参加者に教える時間も準備しています．<br>
                これまでゲームを開発したことがある人も，初挑戦の方も参加をお待ちしています．<br>
                一緒にゲームの力で世界を一緒に救いましょう！
              </p>
            </div>
          </div>
        </div>
        <v-divider/> 
            
        </v-col>
        </v-container>
      </v-main>
      <GJSponsor style="pointer-events:auto"></GJSponsor>
    </div>
    
    </v-col>
</v-app>
    
</template>



<script>
import GJSidebar from "@/components/bar/GJSidebar";
import GJSponsor from "@/components/bar/GJSponsor";

export default {

  components: {
    GJSidebar,
    GJSponsor,
  },
  data() {
    return {
      list: [],
    };
  },
  async created() {
    this.list = [
      {
        img:
          "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/SGJ9-homepage-key-V5.png",          
      },
    ];
  },
  methods: {},
  computed: {
  },
};
</script>

<style scoped>


.Yoko {
  margin-top:0px;
  display: flex;   
  justify-content:center;
}
.Tate {
  margin-top:0px;  
  justify-content:center;
}
@media screen and (max-width: 770px) {
  .Yoko {
    flex-direction: column;
  }
  .Zikko{
    flex-direction: column;
  }
  .Zikkoimg{
    max-width: initial;
  }
}
@media screen and (min-width: 771px) {
  .Zikkoimg{
    max-width: 150px;
  }
  .Exp{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:120px;
  } 
}
@media screen and (min-width: 1264px) {
  .Exp{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:100px;
  }
}

.contents-box{
  background-color:white;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:24px;
  padding-right:24px;
  margin:3px;
  position:relative;
  flex:1;
}
.contents-img{
  width:80%;
  height:200px;
}
.Zikko{
  background-color:lightgray;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  position:relative;
  margin:5px;
}
.Zikkoimg{
  background-color:lightgray;
  flex:1;
  flex-basis: auto;
}
.Zikkoword{
  background-color:ghostwhite;
  padding:2%;
  flex-basis: auto;
  flex:1;
}
img {
  width: 100%;
  max-width: 900px;
}

span {
  margin-left: 1rem;
}

p{
  margin:0;
  padding:0;
  text-align:left;
}



</style>