<template>
  <v-col align="center">
    <html>
      <v-container>
        <div class="tabs">
          <input
            v-if="teacher_profiles.length > 0"
            id="teacher"
            type="radio"
            name="tab_item"
            checked
          />
          <label
            v-if="teacher_profiles.length > 0"
            class="tab_item"
            for="teacher"
            v-on:click="change_grade('teacher')"
            >教員</label
          >
          <!--input
            v-if="d_profiles.length > 0"
            id="doctor"
            type="radio"
            name="tab_item"
          />
          <label
            v-if="d_profiles.length > 0"
            class="tab_item"
            for="doctor"
            v-on:click="change_grade('doctor')"
            >博士課程</label
        -->
          <input
            v-if="m2_profiles.length > 0"
            id="m2"
            type="radio"
            name="tab_item"
          />
          <label
            v-if="m2_profiles.length > 0"
            class="tab_item"
            for="m2"
            v-on:click="change_grade('m2')"
            >修士2年</label
          >
          <input
            v-if="m1_profiles.length > 0"
            id="m1"
            type="radio"
            name="tab_item"
          />
          <label
            v-if="m1_profiles.length > 0"
            class="tab_item"
            for="m1"
            v-on:click="change_grade('m1')"
            >修士1年</label
          >
          <input
            v-if="b4_profiles.length > 0"
            id="b4"
            type="radio"
            name="tab_item"
          />
          <label
            v-if="b4_profiles.length > 0"
            class="tab_item"
            for="b4"
            v-on:click="change_grade('b4')"
            >学部4年</label
          >
          <input
            v-if="b3_profiles.length > 0"
            id="b3"
            type="radio"
            name="tab_item"
          />
          <label
            v-if="b3_profiles.length > 0"
            class="tab_item"
            for="b3"
            v-on:click="change_grade('b3')"
            >学部3年</label
          >
          <input
            v-if="s2_profiles.length > 0"
            id="s2"
            type="radio"
            name="tab_item"
          />
          <label
            v-if="s2_profiles.length > 0"
            class="tab_item"
            for="s2"
            v-on:click="change_grade('s2')"
            >短大2年</label
          >

          <div
            class="tab_content"
            v-for="grade in grades"
            :key="grade.id"
            v-bind:id="grade.contentid"
          >

          <div class="tab_content_description">
  <v-container
    v-for="profile in display_profiles"
    :key="profile.name"
    class="profile-container"
  >
    <div class="profile-image-and-name">
      <img :src="profile.icon" alt="profile image" class="profile-image"/>
      <div class="profile-name">{{ profile.name }}</div>
    </div>
    <div class="profile-introduction">
      <p>{{ profile.comment }}</p>
      <ul>
        <li v-for="(url, title) in profile.mypage" :key="title">
          <a :href="url" target="_blank">{{ title }}</a>
        </li>
      </ul>
    </div>
  </v-container>
</div>


            
          </div>
        </div>
      </v-container>
    </html>
  </v-col>
</template>

<script>
var date = new Date();
date.setMonth(date.getMonth() - 3);
// 今年度を表示
const profilelist = [
    {
      name: "松野裕 （まつの ゆたか）",
      icon: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/matsuno.fe0ed3af.jpeg",
      comment:
        "2015年4月に日大に来て、娘が2016年に生まれました。で2019年はイギリスのブライトン大学で1年間長期海外派遣研究員していました。イギリスが大変になる前に3月にぎりぎり帰ってきました。娘の日々の成長が楽しみです（写真は学生の頃、シアトルのセーフコ・フィールドのベンチで撮った写真です）。",
      homepage: "researcher-web.nihon-u.ac.jp/search/detail?systemId=71630fbe9d457ee161a6f44728c92d05&lang=ja",
      mypage: {
        個人ページ: "https://researcher-web.nihon-u.ac.jp/search/detail?systemId=71630fbe9d457ee161a6f44728c92d05&lang=ja",
        "researchmap":
          "https://researchmap.jp/yutaka_matsuno",
        Facebook: "https://www.facebook.com/yutaka.matsuno.71",
        Twitter: "https://twitter.com/YutakaMatsuno",
        "Google Scholar":
          "https://scholar.google.co.jp/citations?user=3Z-q4SQAAAAJ&amp;hl=ja",
      },
    },
    {
      name: "依田 みなみ（よだ　みなみ）",
      icon: "https://mlhp-image.s3.ap-northeast-1.amazonaws.com/img/bali_150px.JPG",
      comment:
        "助手です．工学博士（電気通信大学）．専門はソフトウェアセキュリティ．IoTやWEBアプリケーションの脆弱性検出など．小さい頃からコンピュータが好きでずっと続けています．1年間海外インターン（フランスパリ），自動車メーカー，WEB企業，受託開発の個人事業主を経験し，2024年に日大に来ました．いろいろ寄り道してしまったので，進路相談でお役に立てそうな事があれば聞いてください．みなさんが楽しく時には真剣に研究できるようにサポートできたらいいなと思っています．\n好きなものはうさぎと宝石，趣味はダンスとファッションです．食費を切り詰めて洋服を買ってしまうので，老後のためにそろそろ改善したいとおもいます．写真はバリの国際会議にて．",
      homepage: "",
      mypage: {
        "resarchmap":
          "https://researchmap.jp/yoda373",
      },
    },
  ];

import { getItem } from '@/plugins/firebase';
import { getTeacherList } from '@/containts/profile.js';
export default {
  data() {
    return {
      nendo: date.getFullYear(),
      profiles: [],
      display_profiles: [],
      grades: [
        {
          id: "teacher",
          contentid: "teacher_content",
          name: "教授",
          Year_offset: 2000,
        },
        { id: "doctor", contentid: "d_content", name: "博士課程"},
        { id: "m2", contentid: "m2_content", name: "修士2年"},
        { id: "m1", contentid: "m1_content", name: "修士1年"},
        { id: "b4", contentid: "b4_content", name: "学部4年"},
        { id: "b3", contentid: "b3_content", name: "学部3年"},
        { id: "s2", contentid: "s2_content", name: "短大2年"},
      ],
      d_profiles: [],
      m2_profiles: [],
      m1_profiles: [],
      b4_profiles: [],
      b3_profiles: [],
      s2_profiles: [],
      teacher_profiles: profilelist,
      tab_num: 0,
    };
  },
  async created() {
    this.profiles = await getItem('profile');
    for (const profile of this.profiles) {
      switch (profile.grade) {
        case '博士課程':
          this.d_profiles.push(profile);
          break;
        case '修士2年':
          this.m2_profiles.push(profile);
          break;
        case '修士1年':
          this.m1_profiles.push(profile);
          break;
        case '学部4年':
          this.b4_profiles.push(profile);
          break;
        case '学部3年':
          this.b3_profiles.push(profile);
          break;
        case '短大2年':
          this.s2_profiles.push(profile);
          break;
      }
    }
    this.display_profiles = this.teacher_profiles;
    this.tab_num =
      (this.teacher_profiles.length > 0 ? 1 : 0) +
      //(this.d_profiles.length > 0 ? 1 : 0) +
      (this.m2_profiles.length > 0 ? 1 : 0) +
      (this.m1_profiles.length > 0 ? 1 : 0) +
      (this.b4_profiles.length > 0 ? 1 : 0) +
      (this.b3_profiles.length > 0 ? 1 : 0) +
      (this.s2_profiles.length > 0 ? 1 : 0);
    document.documentElement.style.setProperty("--tab_num", this.tab_num);
  },
  methods: {
    change_grade: function (val) {
      this.display_profiles = eval("this." + val + "_profiles");
    },
  },
};
</script>

<style src="@/assets/CSS/profile.css" scoped>
:root {
  --tab_num: 10;
}

</style>