import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
Vue.use(VueQuillEditor)

// import Amplify from "aws-amplify";
// import aws_exports from "./aws-exports";
// import {
//   applyPolyfills,
//   defineCustomElements,
// } from "@aws-amplify/ui-components/loader";

// Amplify.configure(aws_exports);
// applyPolyfills().then(() => {
//   defineCustomElements(window);
// });

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
