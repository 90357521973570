<template>
  <v-card>
    <v-col cols="20">
      <p class="text-left">タイトル</p>
      <div>
        <v-text-field v-model="item.title" label="title" />
      </div>
      <br />
      <toggle-button
        v-model="toggle"
        :value="true"
        :labels="{ checked: 'FILE', unchecked: 'PAGE' }"
        :color="{ checked: 'blue', unchecked: 'red' }"
        :width="70"
        :height="30"
      />
      <div v-if="toggle">
        <p class="text-left">URL</p>
        <div>
          <v-text-field v-model="item.url" label="url" />
        </div>
      </div>
      <div v-if="!toggle">
        <p class="text-left">PAGE</p>
        <div>
          <quill-editor v-model="item.url" label="page" ref="quillEditor" />
        </div>
      </div>
    </v-col>
    <v-col v-if="toggle">
      <v-row>
        <input type="file" style="display: none" ref="input" accept="" multiple @change="onUploaded" />
        <v-btn color="primary" @click="btnclick">select pdf</v-btn>
      </v-row>
      <v-row>
        <v-btn v-if="show"></v-btn>
      </v-row>
    </v-col>
    <v-col align="right">
      <v-btn v-if="mode == 'update'" color="error" @click="deletion">削除</v-btn>
      <v-btn color="primary" @click="post">登録</v-btn>
    </v-col>
  </v-card>
</template>

<script>
import { postItem, uploadFile, deleteItem } from '@/plugins/firebase';
export default {
  props: ['item', 'mode'],
  data() {
    return {
      show: false,
      toggle: true,
    };
  },
  methods: {
    btnclick() {
      this.$refs.input.click();
    },
    async post() {
      if (this.toggle) this.item.mode = 'file';
      else if (!this.toggle) this.item.mode = 'page';
      const regex = /https?:\/\/storage.googleapis.com\/mlab-homepage-evacuation.appspot.com*/;
      if (this.toggle && this.item.url != '' && !regex.test(this.item.url)) {
        if (confirm('ファイルのURLがアップロード先のURLではないですがよろしいですか')) await postItem('project', this.item, this.mode);
      } else {
        await postItem('project', this.item, this.mode);
        this.item = {
          url: '',
          title: '',
          icon: '',
        };
        this.$emit('dialog_bloken');
      }
    },
    async deletion() {
      if (confirm(this.item.name + 'を本当に削除しますか')) {
        await deleteItem('project', this.item);
        await alert('削除しました');
        this.$emit('dialog_bloken');
      }
    },
    async onUploaded(e) {
      const file = e.target.files[0];
      this.item.url = await uploadFile(file, 'document');
    },
  },
  created() {
    console.log(this.item, this.mode);
  },
  watch: {
    toggle: function () {
      console.log(this.toggle);
    },
  },
};
</script>
