<template>
  <v-col cols="12">
    <v-btn color="error" @click="create"> 新規作成 </v-btn>
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    <v-data-table :headers="headers" :items="list" :items-per-page="15" item-key="id" :search="search" multi-sort>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { getItem } from '@/plugins/firebase';
export default {
  name: 'Profile',
  props: ['val'],
  data() {
    return {
      profile: {
        name: '',
        Year_of_admission: '',
        comment: '',
        icon: '',
      },
      list: [],
      headers: [
        { text: '氏名', value: 'name' },
        { text: '一言', value: 'comment' },
        { text: '学年', value: 'grade' },
        { text: '編集', value: 'edit', sortable: false },
      ],
      search: '',
    };
  },
  async created() {
    this.list = await getItem('profile');
  },
  methods: {
    create() {
      const create_item = {
        name: '',
        Year_of_admission: '',
        comment: '',
        icon: '',
      };
      this.$emit('edit', create_item, 'new', 'profile');
    },
    edit(item) {
      this.$emit('edit', item, 'update', 'profile');
    },
  },
};
</script>
