<template>
  <v-col align="“left”">
    <v-container>
      <h1>RESEARCH</h1>
      <time datetime="“2017-04-24”">2017.04.24</time>
    </v-container>
    <v-container>
      <p>松野研究室の発表論文を掲示していきます。</p>
    </v-container>
    <html>
      <v-container>
        <div class="tabs">
          <input id="teacher" type="radio" name="tab_item" checked />
          <label class="tab_item" for="teacher">研究発表</label>
          <input id="dis" type="radio" name="tab_item" />
          <label class="tab_item" for="dis">卒業論文、修士論文</label>
          <input id="pub" type="radio" name="tab_item" />
          <label class="tab_item" for="pub">学生による研究発表</label>

          <div class="tab_content" id="teacher_content">
            <div class="tab_content_description">
              <v-container v-for="research in list" :key="research.title" v-show="research.category == type">
                <h2>{{ research.title }}</h2>
                <p v-html="research.comment"></p>
                <div v-if="research.url != 'null'">
                  <p><a :href="research.url" target="_blank">Google Scholar</a></p>
                </div>
                  <p><a :href="research.re_url" target="_blank">researchmap</a></p>
                <v-divider></v-divider>
                <div class="clear"></div>
              </v-container>
            </div>
          </div>

          <div class="tab_content" id="dis_content">
            <div class="tab_content_description">
              <v-data-table v-model="ronbun" :headers="headers" :items="ronbun" :item-per-page="15" item-key="comment" :search="search" :sort-by="year" :sort-desc=true>
                <v-divider></v-divider>
                <div class="clear"></div>
              </v-data-table>
            </div>
          </div>

          <div class="tab_content" id="pub_content">
            <div class="tab_content_description">
              <v-container v-for="research in list" :key="research.title" v-show="research.category == type + 1">
                <h2>{{ research.title }}</h2>
                <p v-html="research.comment"></p>
                <v-divider></v-divider>
                <div class="clear"></div>
              </v-container>
            </div>
          </div>
        </div>
      </v-container>
    </html>
  </v-col>
</template>

<script>
import researchJson from '@/containts/research.json';
export default {
  data() {
    return {
      headers: [
        { text: 'タイトル', value: 'comment' },
        { text: '発表年度', value: 'year' },
      ],
      list: researchJson.list,
      ronbun: researchJson.ronbun,
      type: 0,
    };
  },
  /*researchlist1:[
         {
          title:"2016",
          comment:"",
          url:"",
        },
        {
         title:"",
          comment:"",
          url:"",
        },
       ],
};
  },*/
  created() {
    this.list = researchJson();
    this.ronbun = researchJson();
  },

  methods: {
    change_grade: function (val) {
      this.display_reseach = eval('this' + val + '_reseach');
    },
  },
};
</script>

<style scoped>
ol {
  margin-left: auto;
  width: 600px;
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.name1 {
  font-weight: bold;
  border-bottom: solid 3px #fec601;
}
.name2 {
  font-weight: bold;
  border-bottom: solid 3px #ffb6c1;
}
.name3 {
  font-weight: bold;
  border-bottom: solid 3px #8700fe;
}
.introduction {
  padding: 7px;
  margin: 7px;
}
.clear {
  clear: both;
}
img {
  margin-right: 10px;
}

/*タブ切り替え全体のスタイル*/
.tabs {
  margin-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 1140px;
  margin: 0 auto;
}

/*タブのスタイル*/
.tab_item {
  width: calc(100% / 3);
  height: 50px;
  background-color: #d9d9d9;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  color: #000000;
  display: inline-block;
  /*float: left;*/
  text-align: center;
  font-weight: bold;
  transition: all 0.2s ease;
}

.tab_item:hover {
  opacity: 0.75;
}

/*ラジオボタンを全て消す*/
input[name='tab_item'] {
  display: none;
}

/*タブ切り替えの中身のスタイル*/
.tab_content {
  display: none;
  padding: 40px 40px 0;
  clear: both;
  overflow: hidden;
}

/*選択されているタブのスタイルを変える*/
#teacher:checked + .tab_item {
  background-color: #ff6600;
  color: black;
}

/*選択されているタブのスタイルを変える*/
#teacher + .tab_item {
  border-bottom: 3px solid #d9d9d9;
}

/*選択されているタブのスタイルを変える*/
#dis:checked + .tab_item {
  background-color: #33cc33;
  color: black;
}

/*選択されているタブのスタイルを変える*/
#dis + .tab_item {
  border-bottom: 3px solid #d9d9d9;
}

/*選択されているタブのスタイルを変える*/
#pub:checked + .tab_item {
  background-color: #99cccc;
  color: black;
}

/*選択されているタブのスタイルを変える*/
#pub + .tab_item {
  border-bottom: 3px solid #d9d9d9;
}

/*選択されているタブのコンテンツのみを表示*/

#teacher:checked ~ #teacher_content,
#dis:checked ~ #dis_content,
#pub:checked ~ #pub_content {
  display: block;
}

/* データテーブルのヘッダーとセルのスタイル調整 */
.v-data-table-header .v-data-table__head th,
  .v-data-table .v-data-table__wrapper table tbody tr td {
    text-align: left; /* テキストを左揃えにする */
    vertical-align: top; /* テキストを上揃えにする */
    padding: 8px; /* パディングを統一する */
  }
  /* スマートフォン表示時のスタイル調整 */
  @media screen and (max-width: 600px) {
    .tabs {
      width: 100%;
      padding: 0;
    }
    .tab_item {
      width: 100%;
      font-size: 16px;
    }
    ol {
      width: 100%;
    }
    /* セルの内容が長い場合のスタイル調整 */
    .v-data-table .v-data-table__wrapper table tbody tr td {
    text-align: left; /* 左揃えを保持 */
    padding: 4px; /* スマートフォンでは少しパディングを減らす */
    white-space: normal; /* 折り返しを許可 */
    }
  }

</style>
